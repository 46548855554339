<template>
  <v-container
    class="container-full-size"
  >
    <v-card
      color="basil"
      style="margin-top: 0;"
      flat
    >
      <v-data-table
        :headers="paymentHeaders"
        :items="payments"
        :no-data-text="str['payments_empty']"
        :no-results-text="str['payments_empty']"
        class="elevation-1"
        @click:row="openPayment"
        :loading="isLoading"
        :loading-text="str['processing']"
      >
        <template v-slot:item.type="{ item }">
          {{ getPaymentType(item) }}
        </template>
        <template v-slot:item.date="{ item }">
          {{ convertUtcDate(item.date) }}
        </template>
        <template v-slot:item.value="{ item }">
          {{ item.value ? (item.value + getCurrency(item)) : '-' }}
        </template>
        <template v-slot:item.status="{ item }">
          <span v-bind:class="getPaymentStatus(item).type + '--text'">
            {{ getPaymentStatus(item).label }}
          </span>
        </template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    name: 'client-payments',
    props: ['parent', 'client'],
    data() {
      return {
        str: window.strings,
        convertUtcDate: Utils.convertUtcDate,
        user: Utils.getUser(),
        paymentsViewPermission: Utils.hasPermission('payments_view'),
        getCurrency: Utils.getCurrency,
        isLoading: false,
        payments: [],
        paymentHeaders: [
          { text: window.strings['type'], value: 'type', align: 'center' },
          { text: window.strings['date'], value: 'date', align: 'center' },
          { text: window.strings['value'], value: 'value', align: 'center' },
          { text: window.strings['status'], value: 'status', align: 'center' },
        ],
      }
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      if (this.paymentsViewPermission) {
        this.getPayments()
      }
    },
    methods: {
      refresh: function () {
        this.getPayments()
      },
      getPayments: function () {
        const self = this
        this.isLoading = true
        Api.getPayments(this.client.dbId, function (response) {
          self.isLoading = false
          if (response.success) {
            self.payments = response.data

            if (window.paymentToOpen) {
              for (let i = 0; i < self.payments.length; i++) {
                if (self.payments[i].id === window.paymentToOpen) {
                  self.openPayment(self.payments[i])
                  break
                }
              }
              window.paymentToOpen = null
            }
          }
        })
      },
      getPaymentType: function (payment) {
        const types = Utils.getPaymentTypes()
        const type = types.find(function (t) {
          return t.value === payment.type
        })
        if (type) {
          return type.label
        } else {
          return window.strings['n_a']
        }
      },
      getPaymentStatus: function (payment) {
        let message = {
          type: 'default',
          label: window.strings['n_a'],
        }
        const statusItem = Utils.getPaymentStatus().find(function (item) {
          return item.value === payment.status
        })
        if (statusItem) {
          message = statusItem
        }
        return message
      },
      openPayment: function (payment) {
        const self = this
        this.$isLoading(true)
        Api.getPayment({
          db_id: this.client.dbId,
          id: payment.id,
        }, function (response) {
          self.$isLoading(false)
          const paymentData = response.data[0]

          if (response.success && paymentData) {
            paymentData.db_id = self.client.dbId
            Utils.setStorage('payment', paymentData)
            self.$router.push({ path: '/home/payment' })
          } else {
            self.$alert(
              response.message ? response.message : window.strings['payment_not_found'],
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
    },
  }
</script>
